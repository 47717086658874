import React from "react"
import {Link} from "gatsby"
import PropTypes from "prop-types"
import moment from "moment"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit } from "@fortawesome/free-solid-svg-icons"

import * as styles from "./post-excerpt.module.css"

const PostExcerpt = (props) => {
  return (
    <article className={styles.excerptPostContainer}>
      <header className={styles.excerptPostHeader}>
        <h3 className={styles.postTitle}>
          <Link to={`/${props.name}/`}>
            {props.title}
          </Link>
        </h3>
        <span className={styles.postTimes}>
          <span className={styles.postTimeSet}>
            <FontAwesomeIcon icon={faEdit} size="sm"/>
            <time>{moment(props.createdDate).utc().format(`YYYY-MM-DD`)}</time>
          </span>
        </span>
      </header>
      <section>
        <p className={styles.postExcerpt}>{props.excerpt}</p>
      </section>
    </article>
  )
}

PostExcerpt.propTypes = {
  createdDate: PropTypes.string,
  slug: PropTypes.string,
  title: PropTypes.string,
  excerpt: PropTypes.string,
}

export default PostExcerpt
