import * as React from "react"
import { graphql } from "gatsby"

import GlobalLayout from "../layouts/global-layout"
import SEO from "../components/seo"
import PostExcerpt from "../components/post-excerpt"

export default function IndexPage ({ data }) {
  return (
    <GlobalLayout>
      <SEO title="KSK Site" />
      <h3 className="page-label">Recent 5 posts</h3>
      {data.allFile.edges.map(({node}, idx) => {
        return (
          <PostExcerpt
            key={idx}
            createdDate={node.childMdx.frontmatter.date}
            name={node.name}
            title={node.childMdx.frontmatter.title}
            excerpt={node.childMdx.excerpt}
          />
        )
      })}
    </GlobalLayout>
  )
}

export const query = graphql`
  {
    allFile(
      filter: {sourceInstanceName: {eq: "posts"}, internal: {mediaType: {eq: "text/mdx"}}}
      sort: {fields: childMdx___frontmatter___date, order: DESC}
      limit: 5
    ) {
      edges {
        node {
          name
          childMdx {
            frontmatter {
              title
              date
            }
            excerpt(truncate: true)
          }
        }
      }
    }
  }
`
